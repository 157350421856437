import axios from "axios";
import { BASE_URL } from "../constants/DataConstant";
import { USER } from "../constants/DataConstant";
// import Notification from "../data/notification.json";
import store from "../store/store";

const ax = axios.create({ baseURL: BASE_URL });
let user = USER;

export const createAccount = async (data) => {
  let config = {
    method: "post",
    url: `User/send`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(data),
  };
  const response = await ax.request(config);
  return response.data;
};

export const verifyAccount = async (data) => {
  let config = {
    method: "post",
    url: `User/validate`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  console.log("res", response);
  return response.data;
};

export const getAllOrders = async () => {
  console.log("user.encriptedToken", user);

  let config = {
    method: "get",
    url: `Order/getOrders`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const getAddress = async (shippingAddressId) => {
  console.log("user.encriptedToken", user);

  let config = {
    method: "get",
    url: `Address?AddressId=${shippingAddressId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const AddCategory = async (data) => {
  console.log("user.encriptedToken", user);
  console.log("data", data);

  let config = {
    method: "post",
    url: `Category`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const updateOrderItemStatus = async (data) => {
  console.log("user.encriptedToken", user);

  let config = {
    method: "put",
    url: `Order`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getOrderStatuses = async () => {
  let config = {
    method: "get",
    url: `StatusEvent`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const getShipmentStatuses = async () => {
  let config = {
    method: "get",
    url: `StatusEvent?Entity=OrderDetail`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const getViewOrders = async (data) => {
  let config = {
    method: "get",
    url: `Order/getOrders`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const deleteOrder = async (data) => {
  let config = {
    method: "delete",
    url: `Order`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getAllCategory = async () => {
  let config = {
    method: "get",
    url: `Category`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const addProduct = async (formData) => {
  let config = {
    method: "post",
    url: `Product`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: formData,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getAllProduct = async (data) => {
  let config = {
    method: "get",
    url: `Product`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const editProduct = async (data) => {
  let config = {
    method: "PUT",
    url: `Product`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const updateVendorVariant = async (data) => {
  let config = {
    method: "PUT",
    url: `VendorVariant`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const fetcheVendorVariantProductList = async (data) => {
  console.log("data",data)
  let config = {
    method: "get",
    url: `VendorVariant`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};


export const deleteProduct = async (data) => {
  console.log("user.encriptedToken", user);

  let config = {
    method: "DELETE",
    url: `Product`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const deleteVariantProduct = async (data) => {
  console.log("user.encriptedToken", user);

  let config = {
    method: "DELETE",
    url: `Variant`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const fetchVariantProduct = async (data) => {
  let config = {
    method: "post",
    url: `Variant`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const fetchgetNotification = async (data) => {
  let config = {
    method: "get",
    url: `Notification`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const updateNotification = async (data) => {
  let config = {
    method: "put",
    url: `Notification`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const fetchVendorVariantProduct = async (data) => {
  let config = {
    method: "post",
    url: `VendorVariant`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};


export const fetchImageProduct = async (data) => {
  let config = {
    method: "post",
    url: `Image`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const GenerateShipRocket = async (data) => {
  let config = {
    method: "post",
    url: `Shipment/GenerateShipRocketOrder`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};