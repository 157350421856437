import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../services/Loader";
import { Modal, Button, Form } from "react-bootstrap";
import {
  getAllCategory,
  fetchVendorVariantProduct,
  addProduct,
  fetchVariantProduct,
  fetchImageProduct,
  AddCategory,
} from "../../services/APIService";

const AddProduct = ({ vendorId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [imageError, setImageError] = useState("");
  const [image, setImage] = useState(null);
  console.log("image", image);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [prodId, setprodId] = useState(0);
  console.log("prodId", prodId);
  const [vendorVariant, setVendorVariant] = useState({
    idVendorVariant: 0,
    price: 0,
    discount: 0,
    adminCommission: 0,
    availableQty: 0,
    variantId: 0,
    vendorId: 0,
    crud: "",
    status: "VVA",
    toBeShipped: 0,
    shipped: 0,
    delivered: 0,
    toBeReturned: 0,
    shiprocketCourierId: 0,
    pickup_location:"",
  });
  console.log("vendorVariant.=", vendorVariant);
  const [step, setStep] = useState(1);
  const [variants, setVariants] = useState([]); // State to store fetched variants
  const [product, setProduct] = useState({ variation: "" });
  // const [product, setProduct] = useState({ variation: "", variations: [] });
  const [sku, setSku] = useState("");

  const [variantImages, setVariantImages] = useState([]); // State to store variant images
  console.log("product", product);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newCategory, setNewCategory] = useState({
    categoryId: 0,
    categoryName: "",
    icon: "",
    status: true,
    slug: "",
    parentCategoryId: 0,
    image: "",
    crud: "",
    images: [],
  });

  // const addVariation = () => {
  //   if (
  //     product.variation.trim() &&
  //     !product.variations.includes(product.variation)
  //   ) {
  //     setProduct((prevState) => ({
  //       ...prevState,
  //       variations: [...prevState.variations, prevState.variation],
  //       variation: "", // Reset the input field
  //     }));
  //   }
  // };

  // const removeVariation = (index) => {
  //   setProduct((prevState) => ({
  //     ...prevState,
  //     variations: prevState.variations.filter((_, i) => i !== index),
  //   }));
  // };

  const handleShowAddModal = () => setShowAddModal(true);
  const handleCloseAddModal = () => setShowAddModal(false);

  const handleAddInputChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  const handleAddSubmit = async () => {
    setLoading(true);

    try {
      // Log payload for debugging
      console.log("Payload being sent:", newCategory);

      // Send the payload to the API
      const response = await AddCategory(newCategory);

      // Log and handle the response
      console.log("Response from API:", response);

      if (response && response.message) {
        alert(response.message);
      } else {
        alert("Category added, but response format is unexpected.");
      }

      // Reset form state
      setNewCategory({
        categoryId: 0,
        categoryName: "",
        icon: "",
        status: true,
        slug: "",
        parentCategoryId: 0,
        image: "",
        crud: "",
        images: [],
      });
      handleCloseAddModal();
    } catch (error) {
      console.error("Error while adding category:", error);
      alert("Failed to add category.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllCategory();
        if (response) {
          setCategories(response);
        }
      } catch (error) {
        console.error("Error fetching categories", error);
        setNotification({
          message: "Failed to fetch categories",
          type: "danger",
        });
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryChange = async (event) => {
    const selectedCategory = event.target.value;
    const category = categories.find(
      (cat) => cat.categoryName === selectedCategory
    );
    if (category) {
      setSelectedCategoryId(category.categoryId);
      setValue("catId", category.categoryId);
      try {
        // Fetch subcategories for the selected category
        const subCategoryResponse = await getAllCategory(category.categoryId);
        if (subCategoryResponse) {
          setSubCategories(subCategoryResponse);
        }
      } catch (error) {
        console.error("Error fetching subcategories", error);
        setNotification({
          message: "Failed to fetch subcategories",
          type: "danger",
        });
      }
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const uploadProductImage = async (entityId, entityType) => {
    // if (!image) return;

    const formData = new FormData();
    // formData.append("image", image);
    formData.append("entityType", entityType);
    formData.append("entityId", entityId);
    formData.append("imageFile", image);
    formData.append("IsCoverImage", true);

    try {
      console.log("formData", formData);
      const response = await fetchImageProduct(formData);
      if (response) {
        setNotification({
          message: "Image uploaded successfully!",
          type: "success",
        });
      }
    } catch (error) {
      console.error("Error uploading image", error);
      setNotification({ message: "Failed to upload image", type: "danger" });
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const isValid = await trigger();
    if (!isValid) {
      setNotification({
        message: "Please fill in all required fields.",
        type: "danger",
      });
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("productName", data.productName);
      formData.append("description", data.description);
      formData.append("price", data.price);
      formData.append("catId", selectedCategoryId);
      formData.append("slug", data.slug);
      formData.append("subCatId", data.subCatId);
      formData.append("sku", data.sku);
      // formData.append("image", data.imagefile);

      const response = await addProduct(formData);
      if (response?.productId) {
        setprodId(response?.productId);
        setNotification({
          message: "Product added successfully!",
          type: "success",
        });
        // Upload the image with entity type "V" for Product
        await uploadProductImage(response.productId, "P");

        const payload = {
          productId: response?.productId,
          variation: product?.variation,
          // variation: product?.variations,
          weight: data.weight,
          length: data.length,
          breadth: data.breadth,
          height: data.height,
          // sku: data.sku,
        };

        const res = await fetchVariantProduct(payload);
        console.log("res============", res);
        setVendorVariant((prevState) => ({
          ...prevState,
          variantId: res?.variantId, // Automatically map the first variant
        }));
        if (res?.length > 0) {
          setVariants(res); // Store the fetched variants
          // fetchImages(res[0].variantId); // Fetch images for the first variant
        } else {
          setNotification({
            message: "No variants found for this product.",
            type: "warning",
          });
        }

        setStep(2);
      } else {
        setNotification({ message: "Failed to add product.", type: "danger" });
      }
    } catch (error) {
      console.error("Error adding product:", error);
      setNotification({
        message: "An error occurred while adding the product.",
        type: "danger",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchVariant = async (prodId) => {
    console.log("prodId in fetchVariant", prodId);
    setLoading(true); // Set loading state
    try {
      const payload = {
        productId: prodId,
        variation: product?.variation,
        // variation: product?.variations,
      };

      const response = await fetchVariantProduct(payload);
      console.log("response", response);
      if (response?.length > 0) {
        setVariants(response); // Store the fetched variants
        setVendorVariant((prevState) => ({
          ...prevState,
          variantId: response[0].variantId, // Automatically map the first variant
        }));
        // fetchImages(response[0].variantId); // Fetch images for the first variant
      } else {
        setNotification({
          message: "No variants found for this product.",
          type: "warning",
        });
      }
    } catch (error) {
      console.error("Error save variant:", error);
      setNotification({ message: "Failed to save variants.", type: "danger" });
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // const fetchImages = async (variantId) => {
  //   setLoading(true); // Set loading state
  //   try {
  //     const response = await fetchImageProduct(variantId); // Fetch the variant images
  //     if (response) {
  //       setVariantImages(response); // Store the fetched images
  //     } else {
  //       setNotification({
  //         message: "No images found for this variant.",
  //         type: "warning",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error save variant images:", error);
  //     setNotification({ message: "Failed to save images.", type: "danger" });
  //   } finally {
  //     setLoading(false); // Reset loading state
  //   }
  // };

  const handleSubmitVendorVariant = async (e) => {
    e.preventDefault();
    const isValid = await trigger();
    if (!isValid) {
      setNotification({
        message: "Please fill in all required fields.",
        type: "danger",
      });
      return;
    }

    // Check for negative price
    if (parseFloat(vendorVariant.price) < 0) {
      setNotification({ message: "Price cannot be negative.", type: "danger" });
      return;
    }

    try {
      const data = {
        ...vendorVariant,
        vendorId: vendorId,
        variantId: vendorVariant.variantId || vendorVariant.prodId, // Set variantId
        variation: product?.variation,
        // variation: product?.variations,
      };

      const response = await fetchVendorVariantProduct(data);
      if (response) {
        setNotification({
          message: "Vendor variant saved successfully!",
          type: "success",
        });
        setStep(2);
      } else {
        setNotification({
          message: "Failed to save vendor variant.",
          type: "danger",
        });
      }
    } catch (error) {
      console.error("Error saving vendor variant:", error);
      setNotification({
        message:
          error.response?.data?.message || "Failed to save vendor variant.",
        type: "danger",
      });
    }
  };

  return (
    <div className="dashboard-influence">
      {loading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12">
            <h3 className="mb-2">
              {step === 1 ? "Add Product" : "Add Variant"}
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                {notification.message && (
                  <div
                    className={`alert alert-${notification.type}`}
                    role="alert"
                  >
                    {notification.message}
                  </div>
                )}

                {step === 1 && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="form-group col-xl-5">
                        <label htmlFor="productName">
                          Product Name
                          <span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <input
                          id="productName"
                          {...register("productName", {
                            required: "Product name is required",
                          })}
                          type="text"
                          className={`form-control ${
                            errors.productName ? "is-invalid" : ""
                          }`}
                        />
                        {errors.productName && (
                          <div className="invalid-feedback">
                            {errors.productName.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-xl-7">
                        <label htmlFor="slug">
                          Slug<span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        ( A slug is the part of a website's URL that identifies
                        a specific page.)
                        <input
                          id="slug"
                          {...register("slug", {
                            required: "Slug is required",
                          })}
                          type="text"
                          className={`form-control ${
                            errors.slug ? "is-invalid" : ""
                          }`}
                        />
                        {errors.slug && (
                          <div className="invalid-feedback">
                            {errors.slug.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-xl-12">
                        <div className="form-group">
                          <label htmlFor="description">
                            Description
                            <span style={{ color: "#FF0000" }}> *</span>
                          </label>
                          <textarea
                            id="description"
                            {...register("description", {
                              required: "Description is required",
                            })}
                            className={`form-control ${
                              errors.description ? "is-invalid" : ""
                            }`}
                          />
                          {errors.description && (
                            <div className="invalid-feedback">
                              {errors.description.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-xl-6">
                        <label htmlFor="category">
                          Category<span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 7,
                          }}
                        >
                          <select
                            id="category"
                            className="form-control"
                            onChange={handleCategoryChange}
                            style={{ width: "85%", position: "relative" }} // Adjust width for smaller size
                          >
                            <option value="">Select Category</option>
                            {categories.map((category) => (
                              <option
                                key={category.categoryId}
                                value={category.categoryName}
                              >
                                {category.categoryName}
                              </option>
                            ))}
                          </select>
                          {/* Add Icon */}
                          <i
                            className="fa fa-plus fa-lg text-success ml-2"
                            onClick={handleShowAddModal}
                          ></i>
                        </div>
                      </div>
                      {/* Add Modal */}
                      <Modal show={showAddModal} onHide={handleCloseAddModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add New Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group>
                              <Form.Label>Category Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="categoryName"
                                value={newCategory.categoryName}
                                onChange={handleAddInputChange}
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Slug</Form.Label>
                              <Form.Control
                                type="text"
                                name="slug"
                                value={newCategory.slug}
                                onChange={handleAddInputChange}
                              />
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCloseAddModal}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            onClick={handleAddSubmit}
                            disabled={loading}
                          >
                            {loading ? "Adding..." : "Add Category"}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <div className="form-group col-xl-6">
                        <label htmlFor="subCatId">
                          Sub-Category
                          <span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 7,
                          }}
                        >
                          <select
                            id="subCatId"
                            {...register("subCatId", {
                              required: "Sub-category is required",
                            })}
                            className={`form-control ${
                              errors.subCatId ? "is-invalid" : ""
                            }`}
                            style={{ width: "85%", position: "relative" }} // Adjust width for smaller size
                          >
                            <option value="">Select Sub-Category</option>
                            {subCategories.map((subCategory) => (
                              <option
                                key={subCategory.categoryId}
                                value={subCategory.categoryId}
                              >
                                {subCategory.categoryName}
                              </option>
                            ))}
                          </select>
                          {/* Add Icon */}
                          <i
                            className="fa fa-plus fa-lg text-success ml-2"
                            onClick={handleShowAddModal}
                          ></i>
                        </div>
                        {errors.subCatId && (
                          <div className="invalid-feedback">
                            {errors.subCatId.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-xl-6">
                        <label
                          htmlFor="variation"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="A specific combination of product options that results in a unique product."
                        >
                          Variation<span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 7,
                          }}
                        >
                          <input
                            id="variation"
                            {...register("variation", {
                              required: "Variation is required",
                            })}
                            value={product.variation}
                            onChange={(e) =>
                              setProduct({
                                ...product,
                                variation: e.target.value,
                              })
                            }
                            placeholder="e.g.. color, size, shape"
                            className={`form-control ${
                              errors.variation ? "is-invalid" : ""
                            } mb-2 `}
                            // style={{ width: "85%" }}
                          />
                          {/* <i className="fa fa-plus fa-lg text-success ml-2"></i> */}

                          {/* <input
                        id="variation"
                        {...register("variation", {
                          validate: () =>
                            product.variations.length > 0 ||
                            "At least one variation is required",
                        })}
                        value={product.variation}
                        onChange={(e) =>
                          setProduct((prevState) => ({
                            ...prevState,
                            variation: e.target.value,
                          }))
                        }
                        placeholder="e.g.. color, size, shape"
                        className={`form-control ${
                          errors.variation ? "is-invalid" : ""
                        }`}
                        style={{ width: "85%" }}
                      />
                      <i
                        className="fa fa-plus fa-lg text-success ml-2"
                        style={{ cursor: "pointer" }}
                        onClick={addVariation}
                      ></i> */}
                        </div>
                        {/* Display the variations list */}
                        {/* {product.variations.length > 0 && (
                      <div className="mt-3">
                        <label>Added Variations:</label>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          {product.variations.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px 10px",
                                backgroundColor: "#f8f9fa",
                                borderRadius: "5px",
                                border: "1px solid #ced4da",
                              }}
                            >
                              <span>{item}</span>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger ml-2"
                                onClick={() => removeVariation(index)}
                                style={{ marginLeft: "10px" }}
                              >
                                x
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )} */}
                        {errors.variation && (
                          <div className="invalid-feedback">
                            {errors.variation.message}
                          </div>
                        )}
                      </div>
                      {/* SKU Number Field */}
                      <div className="form-group col-xl-6">
                        <label htmlFor="sku">
                          SKU Number<span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <input
                          id="sku"
                          {...register("sku", {
                            required: "SKU Number is required",
                            pattern: {
                              value: /^[a-zA-Z0-9]{8,12}$/,
                              message:
                                "SKU must be 8-12 alphanumeric characters",
                            },
                          })}
                          value={product.sku}
                          onChange={(e) =>
                            setProduct({ ...product, sku: e.target.value })
                          }
                          placeholder="e.g., ABC1234567"
                          className={`form-control ${
                            errors.sku ? "is-invalid" : ""
                          } mb-2`}
                        />
                        {errors.sku && (
                          <div className="invalid-feedback">
                            {errors.sku.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-xl-6">
                        {/* Weight Field */}
                        <label htmlFor="weight">
                          Weight
                          {/* <span style={{ color: "#FF0000" }}> *</span> */}
                        </label>
                        <input
                          id="weight"
                          {...register("weight", {
                            // required: "Weight is required",
                          })}
                          value={product.weight}
                          onChange={(e) =>
                            setProduct({ ...product, weight: e.target.value })
                          }
                          placeholder="e.g., 1.5kg"
                          className={`form-control ${
                            errors.weight ? "is-invalid" : ""
                          } mb-2`}
                        />
                        {errors.weight && (
                          <div className="invalid-feedback">
                            {errors.weight.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-xl-6">
                        {/* Length Field */}
                        <label htmlFor="length">
                          Length
                          {/* <span style={{ color: "#FF0000" }}> *</span> */}
                        </label>
                        <input
                          id="length"
                          {...register("length", {
                            // required: "Length is required",
                          })}
                          value={product.length}
                          onChange={(e) =>
                            setProduct({ ...product, length: e.target.value })
                          }
                          placeholder="e.g., 30cm"
                          className={`form-control ${
                            errors.length ? "is-invalid" : ""
                          } mb-2`}
                        />
                        {errors.length && (
                          <div className="invalid-feedback">
                            {errors.length.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-xl-6">
                        {/* Breadth Field */}
                        <label htmlFor="breadth">
                          Breadth
                          {/* <span style={{ color: "#FF0000" }}> *</span> */}
                        </label>
                        <input
                          id="breadth"
                          {...register("breadth", {
                            // required: "Breadth is required",
                          })}
                          value={product.breadth}
                          onChange={(e) =>
                            setProduct({ ...product, breadth: e.target.value })
                          }
                          placeholder="e.g., 20cm"
                          className={`form-control ${
                            errors.breadth ? "is-invalid" : ""
                          } mb-2`}
                        />
                        {errors.breadth && (
                          <div className="invalid-feedback">
                            {errors.breadth.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-xl-6">
                        {/* Height Field */}
                        <label htmlFor="height">
                          Height
                          {/* <span style={{ color: "#FF0000" }}> *</span> */}
                        </label>
                        <input
                          id="height"
                          {...register("height", {
                            // required: "Height is required",
                          })}
                          value={product.height}
                          onChange={(e) =>
                            setProduct({ ...product, height: e.target.value })
                          }
                          placeholder="e.g., 50cm"
                          className={`form-control ${
                            errors.height ? "is-invalid" : ""
                          } mb-2`}
                        />
                        {errors.height && (
                          <div className="invalid-feedback">
                            {errors.height.message}
                          </div>
                        )}
                        {/* Image Upload */}
                      </div>
                      <div className="form-group col-xl-12">
                        <label htmlFor="image">Product Image</label>
                        (Less than 256KB)
                        <input
                          id="image"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Add Product
                      </button>
                    </div>
                  </form>
                )}

                {step === 2 && (
                  <>
                    <form onSubmit={handleSubmitVendorVariant}>
                      <div className="form-group">
                        <label htmlFor="price">
                          Price<span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <input
                          type="number"
                          value={vendorVariant.price}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (value >= 0 || e.target.value === "") {
                              setVendorVariant({
                                ...vendorVariant,
                                price: e.target.value,
                              });
                            }
                          }}
                          className="form-control"
                          min="0"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="discount">
                          Discount(%)
                          <span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <input
                          type="number"
                          value={vendorVariant.discount}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (value >= 0 && value <= 100) {
                              setVendorVariant({
                                ...vendorVariant,
                                discount: e.target.value,
                              });
                            }
                          }}
                          className="form-control"
                          min="0"
                          max="100"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="availableQty">
                          Available Quantity
                          <span style={{ color: "#FF0000" }}> *</span>
                        </label>
                        <input
                          type="number"
                          value={vendorVariant.availableQty}
                          onChange={(e) =>
                            setVendorVariant({
                              ...vendorVariant,
                              availableQty: e.target.value,
                            })
                          }
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select
                          className="form-control"
                          value={vendorVariant.status}
                          onChange={(e) =>
                            setVendorVariant({
                              ...vendorVariant,
                              status: e.target.value,
                            })
                          }
                        >
                          <option value="VVA">Active</option>
                          <option value="">In-Active</option>
                        </select>
                      </div>

                      {/* <div className="form-group">
                      <label htmlFor="variation">Variation<span style={{ color: '#FF0000' }}> *</span></label>
                      <input
                        type="text"
                        value={vendorVariant.variation}
                        onChange={e => fetchVariant({ ...vendorVariant, variation: e.target.value })}
                        className="form-control"
                        placeholder="e.g. Size, Color, etc."
                        required
                      />
                    </div> */}

                      {/* <div className="form-group">
                        <label htmlFor="variantImage">
                          Upload Variant Image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="variantImage"
                          onChange={handleImageUpload}
                        />
                        {imageError && (
                          <div className="text-danger">{imageError}</div>
                        )}
                      </div> */}

                      <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                          Submit Vendor Variant
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
