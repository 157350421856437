import React, { useState, useEffect } from "react";
import Loader from "../../services/Loader";
import { getViewOrders } from "../../services/APIService"; // assuming the service file and function

export default function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalSales, setTotalSales] = useState(0);
  const [todaySales, setTodaySales] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);

  useEffect(() => {
    fetchOrderData();
  }, []);

  const discountedAmt = (orderDetail) => {
    // Calculate the discounted amount for an order item
    const { price, qty, discountAmount } = orderDetail;
    const discount = (price * discountAmount) / 100;
    return (price - discount) * qty;
  };

  const DetailsCalculation = (data) => {
    let oDetails = data?.orderDetails || [];
    let subTotal = 0;

    for (let i = 0; i < oDetails.length; i++) {
      subTotal += discountedAmt(oDetails[i]);
    }

    return {
      subTotal,
      netTotal: subTotal + (data.shippingCost || 0), // Add shipping cost
    };
  };

  const fetchOrderData = async () => {
    setIsLoading(true);
    try {
      const orders = await getViewOrders(); // Fetch all orders
      const today = new Date().toISOString().slice(0, 10); // Current date in YYYY-MM-DD format

      let totalSalesAmount = 0;
      let todaySalesAmount = 0;
      let pendingOrderCount = 0;

      // Process all orders
      orders.forEach((order) => {
        const { subTotal, netTotal } = DetailsCalculation(order);

        // Accumulate total sales
        if (order.status !== "ORC" || order.orderStatus !== "Order Cancelled") {
          totalSalesAmount += netTotal;

          // Include in today's sales if the order was updated today
          if (order.updatedAt.slice(0, 10) === today) {
            todaySalesAmount += netTotal;
          }
        }

        // Count orders with pending status
        if (order.status === "ORP" || order.orderStatus === "Order Pending") {
          pendingOrderCount += 1;
        }
      });

      // Update state with calculated values
      setTotalSales(totalSalesAmount);
      setTodaySales(todaySalesAmount);
      setPendingOrders(pendingOrderCount);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard-influence">
      {isLoading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="page-header">
              <h3 className="mb-2">Dashboard</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-inline-block">
                  <h5 className="text-muted">Total Sales</h5>
                  <h2 className="mb-0">₹{totalSales.toLocaleString()}</h2>
                </div>
                <div className="float-right icon-circle-medium icon-box-lg bg-info-light mt-1">
                  <i className="fa fa-user fa-fw fa-sm text-info"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-inline-block">
                  <h5 className="text-muted">Today Sales</h5>
                  <h2 className="mb-0">₹{todaySales.toLocaleString()}</h2>
                </div>
                <div className="float-right icon-circle-medium icon-box-lg bg-primary-light mt-1">
                  <i className="fa fa-user fa-fw fa-sm text-primary"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-inline-block">
                  <h5 className="text-muted">Total Pending Orders</h5>
                  <h2 className="mb-0">{pendingOrders}</h2>
                </div>
                <div className="float-right icon-circle-medium icon-box-lg bg-secondary-light mt-1">
                  <i className="fa fa-user fa-fw fa-sm text-secondary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
