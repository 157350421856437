import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { USER } from "../constants/DataConstant";
// comman comps
import Login from "../comps/comman/Login";
// admin comps
import Dashboard from "../comps/admin/Dashboard";
import Home from "../comps/admin/Home";
import Orders from "../comps/admin/Orders";
import AddProduct from "../comps/admin/AddProduct";
import Notification from "../comps/admin/Notification";
import ProductList from "../comps/admin/ProductList";
import Shiprocket from "../comps/admin/Shiprocket";

export default function Router() {
  let user = USER !== null;
  //const user = useSelector((state) => state.user.value);

  return (
    <BrowserRouter>
      <Routes>
        {/* visitor routes */}
        <Route path="" element={<Login />} />
        {/* admin routes */}
        <Route
          path="dashboard"
          element={user ? <Dashboard /> : <Navigate to="/" />}
        >
          <Route path="" element={<Home />} />
          <Route path="orders" element={<Orders />} />
          <Route path="addProduct" element={<AddProduct />} />
          <Route path="notification" element={<Notification />} />
          <Route path="productlist" element={<ProductList />} />
          <Route path="shiprocket" element={<Shiprocket />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
