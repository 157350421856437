import React, { useEffect, useState } from "react";
import Loader from "../../services/Loader";
import { GenerateShipRocket, getAllOrders } from "../../services/APIService";

const Shiprocket = ({ isLoading }) => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ordersPerPage] = useState(5); // Fixed number of orders per page
  const [selectedOrder, setSelectedOrder] = useState(null);
  console.log("selectedOrder shipRocket",selectedOrder)
  const [errorMessage, setErrorMessage] = useState("");

  // debugger
  // Fetch all orders
  const fetchOrders = async () => {
    try {
      const data = await getAllOrders();
      setOrders(data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  // Handle checkbox selection
  const handleCheckboxChange = (orderId) => {
    setSelectedOrder(orderId);
    setErrorMessage(""); // Clear error message
  };

  // Handle Proceed button click
  // const handleProceed = async () => {
  //   if (!selectedOrder) {
  //     setErrorMessage("Please select a checkbox or order.");
  //     return;
  //   }

  //   try {
  //     const response = await GenerateShipRocket(selectedOrder);
  //     console.log("Shiprocket response",response);
  //     alert(`Shipment generated for order ID: ${selectedOrder}`);
  //   } catch (error) {
  //     console.error("Error generating shipment:", error);
  //   }
  // };

  const handleProceed = async () => {
  if (!selectedOrder) {
    setErrorMessage("Please select a checkbox or order.");
    return;
  }

  const selectedOrderDetails = orders.find(order => order.orderId === selectedOrder);
  
  if (!selectedOrderDetails) {
    setErrorMessage("Order details not found.");
    return;
  }

  const payload = {
    order_id: String(selectedOrderDetails.orderId),
    order_items: selectedOrderDetails.orderDetails.map((detail) => ({
      order_item_id: detail.orderDtlId,
    })),
    // order_items: selectedOrderDetails.orderDetails.map(
    //   (detail) => detail.orderDtlId
    // ),
  
  };
  console.log("Shiprocket payload",payload)

  try {
    const response = await GenerateShipRocket(payload);
    console.log("Shiprocket response",response)
    alert(`Shipment generated for order ID: ${selectedOrder}`);
  } catch (error) {
    console.error("Error generating shipment:", error.response?.data || error);
  }
};


  // Change page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(orders.length / ordersPerPage);

  return (
    <div className="dashboard-influence">
      {isLoading && <Loader />}
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="page-header">
              <h3 className="mb-2">Shiprocket</h3>
            </div>
          </div>
        </div>
        <div className="row">
          {orders.length === 0 ? (
            <p>No orders available.</p>
          ) : (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Order ID</th>
                  <th>Order Number</th>
                  <th>Status</th>
                  <th>Shipping Cost</th>
                  <th>Order Details</th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.map((order) => (
                  <tr key={order.orderId}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedOrder === order.orderId}
                        onChange={() => handleCheckboxChange(order.orderId)}
                      />
                    </td>
                    <td>{order.orderId}</td>
                    <td>{order.orderNumber}</td>
                    <td>{order.status}</td>
                    <td>{order.shippingCost}</td>
                    <td>
                      {order.orderDetails.map((detail) => (
                        <div key={detail.orderDtlId}>
                          <p>
                            Product: {detail.productName} <br />
                            Quantity: {detail.qty} <br />
                            Price: {detail.price} <br />
                            Vendor: {detail.vendorName}
                          </p>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-primary"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="btn btn-primary"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
        {selectedOrder && (
          <button className="btn btn-success mt-3" onClick={handleProceed}>
            Proceed
          </button>
        )}
      </div>
    </div>
  );
};

export default Shiprocket;
