import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Loader from "../../services/Loader";
import { createAccount, verifyAccount } from "../../services/APIService";
import { login } from "../../store/slices/userSlice";
import { useSelector, useDispatch } from "react-redux";

export default function Login() {
 // const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [btnText, setBtnText] = useState("Submit");

  const [passToggle, setPassToggle] = useState("text");
  const [passToggleClass, setPassToggleClass] = useState("fas fa-eye");
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
        localStorage.setItem("UserToken", JSON.stringify({ 
          encriptedToken:"TzcX9izb88HGpP4TGF37PQYvvg/wdl9hEH6b3VCt4k5Zw/d2ZLKcxHCH1K353PXJtnJVVTzl0CcGdbds25CrdxjdJfNbFn4Xri0BBCQY5qjItCMa3sgsuYv48pOQyPTyHL4tBJBGCiu5UdC/QPJN73LEO01XEG1043KTeYgOcQzqdKOpMMbWt5SGScSKizxtaBYnsIgwR6TfBN1v6OADzSpE2S+kbsdA5PdsN6ZO5rCIYjdoiBf74NGlNSYcMZ5DdRnmEGnuDbdjrK6r9a2QJ3AvzDvimkrperiDREPuL1D3LymTipWfctXrv0u9iLx+tbPdhjS3pMo9HzQy/EXXnQ==" }));
    // if (user) {
    //   navigate("/dashboard");
    // }
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, isOtpSent]);

  const onSubmit = async (data) => {
    try {
      if (btnText == "Submit") {
        sendOTP(data);
      } else {
        verifyOTP(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendOTP = async (data) => {
    try {
      setIsLoading(true);
      const response = await createAccount(data.phoneNumber);
      if (response == "OTP sent successfully.") {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText(response);
        setBtnText("Verify");
        setIsOtpSent(true);
        setSeconds(30);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };
  const verifyOTP = async (data) => {
    try {
      setIsLoading(true);
      setShowMsg(false);
      const response = await verifyAccount(data);
      console.log('response',response)
      if (response.encriptedToken) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText("OTP Matched..!");
        dispatch(login(response));
        localStorage.setItem("UserToken", JSON.stringify(response));
        navigate("/dashboard");
        window.location.reload();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText(error?.response?.data);
    }
  };

  const passwordToggle = () => {
    if (passToggleClass == "fas fa-eye") {
      setPassToggleClass("fas fa-eye-slash");
      setPassToggle("text");
    } else {
      setPassToggleClass("fas fa-eye");
      setPassToggle("password");
    }
  };

  return (
    <div className="splash-container py-5">
      {isLoading && <Loader />}
      <div className="card ">
        <div className="card-header text-center">
          <a href="javascript:void(0);">
            <img
              className="logo-img pb-3"
              src="../assets/images/logo.png"
              alt="logo"
            />
          </a>
          <h2>Login</h2>
          <span className="splash-description">
            Please enter login credentials.
          </span>
        </div>
        <div className="card-body">
          {showMsg && (
            <div
              className={msgClass}
              //className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              <strong>{msgText}</strong>
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <input
                className="form-control form-control-lg"
                type="text"
                placeholder="Mobile No"
                {...register("phoneNumber", {
                  required: "Enter mobile no",
                  minLength: {
                    value: 10,
                    message: "Enter valid mobile no",
                  },
                  maxLength: {
                    value: 10,
                    message: "Enter valid mobile no",
                  },
                  pattern: {
                    message: "Enter valid mobile no",
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  },
                })}
              />
              {errors.phoneNumber && (
                <p className="text-danger">{errors.phoneNumber.message}</p>
              )}
            </div>
            {isOtpSent && (
              <div className="form-group">
                <input
                  className="form-control form-control-lg"
                  type={passToggle}
                  placeholder="OTP"
                  {...register("otp", {
                    required: "Enter otp",
                  })}
                />

                {/* <div className="input-group-append" onClick={passwordToggle}>
                  <button type="button" className="btn">
                    <i className={passToggleClass}></i>
                  </button>
                </div> */}
                {errors?.otp && (
                  <p className="text-danger">{errors?.otp.message}</p>
                )}
                <div>
                  {seconds > 0 || minutes > 0 ? (
                    <span>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  ) : (
                    <span className="d-flex">
                      Didn't recieve code.?{" "}
                      <a
                        role="button"
                        href="javascript:void(0);"
                        className="mb-0 fw-bold ms-2"
                        onClick={() => {
                          sendOTP({
                            phoneNumber: getValues("phoneNumber"),
                          });
                        }}
                      >
                        {" "}
                        Resend OTP
                      </a>
                    </span>
                  )}
                </div>
              </div>
            )}

            {/* <div className="form-group">
              <label className="custom-control custom-checkbox">
                <input className="custom-control-input" type="checkbox" />
                <span className="custom-control-label">Remember Me</span>
              </label>
            </div> */}

            <button type="submit" className="btn btn-primary btn-lg btn-block">
              {btnText}
            </button>
          </form>
        </div>
        {/* <div className="card-footer bg-white p-0  ">
          <div className="card-footer-item card-footer-item-bordered">
            <a href="#" className="footer-link">
              Create An Account
            </a>
          </div>
          <div className="card-footer-item card-footer-item-bordered">
            <a href="#" className="footer-link">
              Forgot Password
            </a>
          </div>
        </div> */}
        {/* <div>
          <h1>Counter: {counter}</h1>
          <button onClick={() => dispatch(increment())}>Increment</button>
          <button onClick={() => dispatch(decrement())}>Decrement</button>
        </div> */}
      </div>
    </div>
  );
}
